@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Tenor+Sans&family=Yeseva+One&display=swap");
/* @import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap"); */
:root {
  background-color: black;
  color: white;

  /* font-family: "Inter", sans-serif;
  font-optical-sizing: auto; */

  font-family: "Tenor Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
}

video {
  /* z-index: inherit; */
  max-width: none;
}

.mainText {
  font-size: 80px;
  line-height: 80px;
}

@media screen and (min-width: 768px) {
  .mainText {
    font-size: 128px;
    line-height: 128px;
  }
}
